.home {
    display: flex;
    .homeContainer {
      flex: 6;
  
   
      .widgets,
      .charts {
        display: flex;
        padding: 20px;
        gap: 20px;
      }
      .charts {
        padding: 5px 20px;
      }
  
      .listContainer {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 20px;
  
        .listTitle {
          font-weight: 500;
          color: gray;
          margin-bottom: 15px;
        }
      }
    }
  
  }
  

  /* Main  */  
// .main-container {
//     grid-area: main;
//     overflow-y: auto;
//     padding: 20px 20px;
//     color: rgba(255, 255, 255, 0.95);
//   }
  
//   .main-title {
//     display: flex;
//     justify-content: space-between;
//   }
  
//   .main-cards {
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr 1fr;
//     gap: 20px;
//     margin: 15px 0;
//   }
  
//   .card1 {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//     padding: 8px 15px;
//     border-radius: 5px;
//   }
  
//   .card1:first-child {
//     background-color: #1f93ff;
//   }
  
//   .card1:nth-child(2) {
//     background-color: #ff6d00;
//   }
  
//   .card1:nth-child(3) {
//     background-color: #2e7d32;
//   }
  
//   .card1:nth-child(4) {
//     background-color: #d50000;
//   }
//   .card1:nth-child(5) {
//     background-color: #1f93ff;
//   }
//   .card1-inner {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }
  
//   .card1-inner > .card_icon {
//     font-size: 25px;
//   }
  
//   .charts {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     gap: 20px;
//     margin-top: 60px;
//     height: 300px;
//   }