.modal-section {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;

  .modal-content {
    width: 30%;
    min-width: 450px;
    position: relative;
    animation: slide ease 0.5s;
    margin-top: 20vh;

    .account {
      // width: 50%;
      margin-right: 10px;

      // font-size: 1.5rem;
    }

    .account-btn {
      font-size: 1.6rem;
      font-weight: 300;
      padding: 8px 1rem;
    }

    .close-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 3rem;
      height: 3rem;
      border: 1px solid var(--color-dark);
      border-radius: 50%;
      padding: 2px;
      cursor: pointer;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateY(-30rem);
  }
  100% {
    transform: translateY(0);
  }
}
