 /* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}  */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");



:root {
  --font-family: "Poppins", sans-serif;
  --dark-blue: #0a1930;
  --light-blue: #1f93ff;

  --color-white: #fff;
  --color-dark: #333;

  --color-grey: #eee;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  --color-purple: #9d0191;
  --color-orange: #ff7722;

  --color-primary: #f0c040;
   /* #007bff; */
  --color-success: #28a745;
  --color-danger: orangered;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

.site-container{

  min-height: 100vh;

}
main{
  flex: 1;
}
body {
  font-family: var(--font-family);
}


section {
  width: 100%;
  padding: 4rem 0;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}


/* UTILITY CLASSES */

/* Flex */
.--flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.--flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.--flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.--flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.--dir-column {
  flex-direction: column;
}

.--flex-dir-column {
  display: flex;
}

.--align-center {
  display: flex;
  align-items: center;
}

.--100vh {
  height: 100vh;
}

.--mh-100vh {
  min-height: 100vh;
}

/* Grid */
.--grid-15 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}
.--grid-25 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}

/* Center All */
.--center-all {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
  text-align: center;
}

/* Heading */
h1,
h2,
h3,
h4 {
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-dark);
  margin-bottom: 1rem;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.3;
  color: var(--color-dark);
}
.--text-xl {
  font-size: 4.5rem;
}
.--text-lg {
  font-size: 4rem;
}

.--text-md {
  font-size: 3rem;
}

.--text-sm {
  font-size: 1.2rem;
  font-weight: 300;
}

.--text-p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.3;
  color: var(--color-dark);
}

.--fw-bold {
  font-weight: 600;
}
.--fw-thin {
  font-weight: 200;
}

/* Text Color */
.--text-light {
  color: #fff;
}

.--color-primary {
  color: #f0c040;
  /* #007bff; */
}
.--color-danger {
  color: orangered;
}
.--color-success {
  color: #28a745;
}

.--color-white {
  color: #fff;
}

/* Center Text */
.--text-center {
  text-align: center;
}

/* Card */
.--card {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

/* Margin */
.--m {
  margin: 1rem;
}
.--ml {
  margin-left: 1rem;
}
.--mr {
  margin-right: 1rem;
}

.--mb {
  margin-bottom: 1rem;
}

.--my {
  margin: 1rem 0;
}
.--mx {
  margin: 0 1rem;
}

.--m2 {
  margin: 2rem;
}

.--ml2 {
  margin-left: 2rem;
}
.--mr2 {
  margin-right: 2rem;
}

.--mb2 {
  margin-bottom: 2rem;
}

.--my2 {
  margin: 2rem 0;
}

.--mx2 {
  margin: 0 2rem;
}

/* Padding */
.--p {
  padding: 1rem;
}
.--p2 {
  padding: 2rem;
}
.--py {
  padding: 1rem 0;
}
.--py2 {
  padding: 2rem 0;
}
.--px {
  padding: 0 1rem;
}
.--px2 {
  padding: 0 2rem;
}

.--btn {
  font-size: 1.6rem;
  font-weight: 400;
  padding: 6px 8px;
  margin: 0 5px 0 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.--btn:hover {
  transform: translateY(-2px);
}

.--btn-lg {
  padding: 8px 10px;
}

.--btn-block {
  width: 100%;
}
/*  #007bff;
#fff; */
.--btn-primary {
  color: #000000; 
  background: #f0c040;
 
}
.--btn-secondary {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}
.--btn-danger {
  color: #fff;
  background: orangered;
}

.--btn-success {
  color: #fff;
  background: #28a745;
}

.--btn-red {
  color: #fff;
  background: rgba(189, 9, 9, 0.847);
}

/* Background */
.--bg-light {
  background: #fff;
}
.--bg-dark {
  background: var(--color-dark);
}
.--bg-primary {
  background: var(--color-primary);
}
.--bg-success {
  background: var(--color-success);
}
.--bg-grey {
  background: var(--color-grey);
}
.--bg-danger {
  background: var(--color-danger);
}

.--form-control {
  font-size: 1.6rem;
  font-weight: 300;
}

.--form-control > * {
  margin: 5px 0;
}

.--form-control input {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}
.--form-control select {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
}

.--form-control label {
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  min-width: 7rem;
  color: var(--color-dark);
  margin-right: 1rem;
}

@media screen and (max-width: 600px) {
  .--flex-dir-column {
    flex-direction: column;
  }
}

.--block {
  display: block;
}
.--inline-block {
  display: inline-block;
}

.--width-100 {
  width: 100%;
}

.--width-500px {
  width: 500px;
}

.--line {
  position: relative;
}
.--line::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 5rem;
  height: 3px;
  margin-bottom: 1rem;

  background: rgb(217, 8, 170);
  background: linear-gradient(
    135deg,
    rgba(163, 1, 191, 1) 44%,
    rgba(217, 8, 170, 1) 57%
  );
}

.--list-style-none {
  list-style: none;
}

.--profile-img {
  width: 6rem;
  height: 6rem;
  border: 1px solid #ccc;
  border-radius: 50%;
}

a {
  font-size: 1.4rem;
  color: var(--dark-blue);
  text-decoration: none;
  transition: all 0.2s;
}

a:hover {
  color: var(--color-dark);
  font-size: 1.5rem;
}

.ql-editor {
  min-height: 200px;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  font-size: 1rem;
}

.pagination .page-num {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: normal;
  color: #333;
  border: 1px solid #333;
  margin: 2px;
}

.pagination .page-num:hover {
  color: #fff;
  background-color: var(--color-primary);
}
.activePage {
  color: #fff;
  background-color: var(--color-primary);
  height: 100%;
}

.active {
  position: relative;
  color: var(--color-danger);
}

.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.formcard {
  width: 100%;
  max-width: 500px;
  padding: 1rem;
  font-size: 1.6rem;
}

.formgroup {
  border: 1px solid var(--dark-blue);
  padding: 5px;
}

.--hr {
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;
}

.activeImg {
  border: 1px solid var(--color-orange);
}

.--underline {
  border-bottom: 1px solid #ccc;
}

.react-star-rate__star {
  color: red;
}

.--cursor {
  cursor: pointer;
}






 .statics{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  margin-top: 2rem;
 

}

 .stats{
  background: var(--primary-color);
  padding: 30px;
  border-radius: 5px;
  height: 320px;
  padding-bottom: 50px;
 }

 .tooltip_style{
  background: var(--body-bg) !important;
  color: #fff;
 }

 .stats_title{
  color: #000;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 20px;
 }

 .recomended_product_wrapper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 2rem;
  column-gap: 2rem;
 }
 .recomended_product_card{
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  
 }
 .recomended_product_card:nth-child(1) {
  background: var(--recommend-car01-bg);
 }
 .recomended_product_card:nth-child(2) {
  background: var(--recommend-car02-bg);
 }
 .recomended_product_card:nth-child(3) {
  background: var(--recommend-car03-bg);
 }
 .recomended_product-top h5 {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  color: var(--body-bg);
  margin-bottom: 10px;
 }
 .recomended_product-top h5 span i{
 font-size: 1.2rem;
 }
 .recomended_product-bottom h4 {
  font-size: 1.3rem;
  margin: 10px 0px;
  }
.recomended_product-other {
    display: flex;
    align-items: center;
    justify-content: space-between;
    }
.recomended_icons {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      } 
.recomended_icons p:nth-child(1) {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        }  
.site-container {
  min-height: 100vh;
}
main {
  flex: 1;
}
.skip-link{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #333;
  color: white;
  padding: .5rem;
  translate: 0 -100%;
  transition: translate 150ms ease-in-out;
}
.skip-link:focus{
translate: 0;
}
button.thumbnail {
  padding: 0;
}

.card{
  border: none;
}
.bodylog{
  margin: 0;
  padding: 0;
  width:510px;
  height:510px;
  justify-content: center;
  align-items: center; 
  box-sizing: border-box;
  background: #23242a;
  font-size: 'poppins', sans-serif ;
}
.sign{
  width:490px;
  /** Sizeable height for the form **/
  height:490px;
  /* Responsive layout */
  display:flex;
  /* Display vertically at the center */
  justify-content: center;
  /* Display horizontally at the center */ 
  align-items: center;
  /* Background image from unsplash */
  /* background :url(''); */
  /* To cover the entire screen
  https://res.cloudinary.com/mafuz-enterprise/image/upload/v1662316880/pawel_xn8nps.jpg
   */
  background-size:cover;
  /* Image position */
  background-position:center;
  /* To not repeat the image beyond its scale */
  background-repeat:no-repeat;

  border-radius: 15px; 
}
 
.extra-small-container{
   /* height: 400px;
  max-width: 350px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   border-radius: 15px;
    background-color: #fffdd0;  */
  /* border-radius: 15px 50px 30px 5px; */
  width:450px;
  /** Sizeable height for the form **/
  height:450px;
  /** Black color with opacity **/
  background-color:rgba(0,0,0,0.5);
  /** Responsive layout **/
  display:flex;
  /** One element on top of the other **/
  flex-direction:column;
  /** Vertically at the center **/
  justify-content: center;
  /** Smooth corners **/
  border-radius:10px;
  /** Top, bottom, right, and left spacing between form and its content **/
  padding:20px; 
 
}
.form-group{
  /* Bottom space between one form-group and the other*/
  margin-bottom:20px;
  /* Responsive layout */
  display: flex;
  /* One element on top of the other */
  flex-direction: column;
}
.form-group label{
  /** Medium font size **/
  
  font-size:16px;
  /** Visible color **/
  color:white;
  /** Bottom space between it and the input form **/
  margin-bottom:5px; 
}
.input{
  width: 100%;
  /** Size-able height of the input **/
  height:40px;
  /** Smooth corners on all edges **/
  border-radius:5px;
  /** No line surrounding the input **/
  border:none;
  /** Top, right, bottom, right spacing to where content starts **/
  padding:10px; 
  /** Medium font **/
  font-size:16px;
  /** Visible color **/
  color:white;
  /** Dark background with some opacity **/
  background-color:rgba(0,0,0,0.5); 
}
.cont{
  width: 20;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.head{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
 
  width: 100%;
  border-radius: 15px 15px 0 0;
}
.head-det{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
  background-color: #282828;
  width: 100%;
}

.btn-primary,
.btn-outline-primary {
  background-color: #f0c040;
  color: #000000;
 
 
}

.savelogin {
  background-color: #45f3ff;
  color: Black;
  width: 100%;
}

.save {
  background-color: rgb(101, 117, 212);
  color: #ffffff;
  width: 100%;
}

.back {
  background-color: #414040;
  color: #ffffff;
  width: 100%;
}

.my-button {
  background-color: #f0c040;
  color: #000000;
  width: 70%;
  height: 40px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: bold;
  
}
.btnright {
  background-color: rgb(14, 167, 14);
}
.col-button {
  background-color: #EEEEEE;
  color: #000000;
  text-align: left;
  font-size: 25px;
  width: 100%;
}
.desc-text-truncate{
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #000000;
}
.title-text-truncate{
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.rating span {
  color: #ffc000;
}

.cardi{
  background-color: #EEEEEE;
  
}
.cardi-ex{
  background-color: #EEEEEE;
  height:90px;
 
}
.cardbody{
  background-color: rgb(61, 61, 59);
  color: #ffffff;
  
}

.export-wrapper{
  
  width:100px;
  height:38px;
 
  
}
.export {
  width:100px;
  height:38px;
  
  background-color: rgb(14, 167, 14);
}
.cost-cen{
  background-color: #2884ff;
}
.line-cen{
  background-color: #725cff;
}
.chart-cen{
  background-color: #56c57a;
}
.leg-cen{
  background-color: #e1424e;
}
.print-wrapper{
  
  width:100px;
  height:38px;
 
  
}
.print {
  width:100px;
  height:38px;
  
  background-color: rgb(101, 117, 212);
}

.stockin {
  width:90px;
  height:38px;  

  background-color: rgb(22, 233, 166);
}
.stockout {
   width:99px;
  height:38px; 
  
  background-color: rgb(237, 46, 158);
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.customClass {

  padding: 3px;
}
.react-bootstrap-table table {
  overflow: auto;
  display: block;
  table-layout: auto;
  }
/* table {
  margin : 0 auto;
  text-align: center;
 
} */
/* table tr td {
  padding : 10px;
  text-align:center;


} */

.eye {
  color: #000000;
}
.edit {
  color: #0004ff;
}
.delete {
  color: #f4a5a5;
}
.icon span {
  color: #ffffff;
  position: absolute;
}
.category{
  margin-left: 35px;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}
.product img {
  width: 100%;
  max-width: 400px;
}
.product-info {
  padding: 1rem;
}
.img-large {
  max-width: 100%;
}
.img-thumbnail {
  height: 80px;
}
.img-thumb {
  height: 15px;
}
.small-container {
   max-width: 800px; 
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
   border-radius: 15px; 
  border-color: #0004ff;

}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}
.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f08000;
  color: #f08000;
}

/* sidebar */
.navbar-brand {
  font-weight: bold;
}
.side-navbar {
  width: 240px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: #100901;
  transition: 0.5s;
}

.site-container {
  transition: 0.4s;
}
.active-cont {
  margin-left: 240px;
}
.active-nav {
  left: 0;
}
.text-bold {
  font-weight: bold;
}


/* Map */

.full-box {
  height: 100vh;
  max-width: initial;
}
.full-box header,
.full-box footer {
  display: none;
}
.full-box .container {
  height: 100vh;
  max-width: initial;
  margin: 0;
  padding: 0;
}

.full-box .mt-3 {
  margin-top: 0 !important;
}

.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  width: 25rem;
  height: 3rem;
  display: flex;
}
.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}
.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}




















