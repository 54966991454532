.radio-label {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    margin-right: 7px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 100%;
    padding: 5px;
    vertical-align: middle;
  }
  
  .radio-input {
    margin: 0;
    visibility: hidden;
  }
  
  .radio-input:checked + span {
    border: 2px solid var(--color-primary);
  }
  
  .radio-input:checked + span:after {
    opacity: 1;
  }
  
  .custom-radio {
    left: -8px;
    top: 6px;
    cursor: pointer;
    width: 17px;
    height: 17px;
    border: 2px solid #9a9a9a;
    border-radius: 50%;
    display: inline-block;
    position: relative;
  }
  
  /* for inner filled circle */
  .custom-radio::after {
    content: "";
    width: 12px;
    height: 12px;
    background: var(--color-primary);
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
  }
  