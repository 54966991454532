.categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}
.category {
  width: 30rem;
  height: 36rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-right: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  padding: 1rem;
  background-color: #fff;
  border-radius: 3px;

  img {
    width: 100%;
    height: 78%;
    cursor: pointer;
    object-fit: cover;
  }
}
