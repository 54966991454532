.footer {
margin-top: auto;

  background-color: #010129;
  color: #fff;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  // position: fixed;
  // bottom: 0;
  width: 100%;
  p {
    color: #fff;
  }

}
