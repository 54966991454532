.otp-area{
   
    width: 70%;
    margin: 20px auto;
    display: flex;gap: 10px;
  
    input{
        // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        // box-shadow: 2px 4px 10px 1px #c9c9c978;
      width: 50px;
      padding: 10px;
      outline: none;
      text-align: center;

      &:focus{
        border: 2px solid #cdc032;
      }
    }
   
  }